/* .bn-container[data-theming-css-variables-demo][data-color-scheme] {
  --bn-colors-editor-text: #222222;
  --bn-colors-editor-background: #7d0000;
  --bn-colors-menu-text: #ffffff;
  --bn-colors-menu-background: #9b0000;
  --bn-colors-tooltip-text: #ffffff;
  --bn-colors-tooltip-background: #b00000;
  --bn-colors-hovered-text: #ffffff;
  --bn-colors-hovered-background: #b00000;
  --bn-colors-selected-text: #ffffff;
  --bn-colors-selected-background: #c50000;
  --bn-colors-disabled-text: #9b0000;
  --bn-colors-disabled-background: #7d0000;
  --bn-colors-shadow: #640000;
  --bn-colors-border: #870000;
  --bn-colors-side-menu: #bababa;
  --bn-color-highlight-colors: #ffffff;
  --bn-border-radius: 4px;
  --bn-font-family: Helvetica Neue, sans-serif;
} */

/* Adds border and shadow to editor */
.bn-container[data-theming-css-demo] .bn-editor {
  color: #191b33;
  background-color: transparent;
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 24px;
  font-weight: 700;
}

/* Makes slash menu hovered items blue */
/* .bn-container[data-theming-css-demo]
  .bn-suggestion-menu-item[aria-selected="true"],
.bn-container[data-theming-css-demo] .bn-suggestion-menu-item:hover {
  background-color: white;
} */

.bn-inline-content-section {
  align-self: center !important;
  margin-top: "-10px" !important;
}

.react-renderer.node-mention.bn-ic-react-node-view-renderer {
  display: inline-flex !important;
  align-items: center !important;
  vertical-align: middle !important;
}
